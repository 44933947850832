<template>
	<div>
		<v-layout class="flex-wrap">
			<div class="pt-3 d-flex align-start justify-space-between w-100">
				<v-flex class="custom topStatusSelect">
					<v-select
						v-model="status"
						solo
						class="listing-select"
						hide-details
						:items="listingStatus"
						item-text="text"
						item-value="value"
						:menu-props="{ offsetY: true, bottom: true }"
						@change="statusFilterRows()"
					>
						<template v-slot:item="{ item }">
							<v-list-item-action>
								<v-avatar
									style="
										height: 26px !important;
										min-width: 26px !important;
										width: auto !important;
										max-width: auto !important;
									"
									:color="item.status_color"
								>
									<span class="white--text">{{ item.status_count }}</span>
								</v-avatar>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title v-text="item.text"></v-list-item-title>
								<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
							</v-list-item-content>
						</template>
					</v-select>
				</v-flex>
				<v-spacer></v-spacer>
				<div class="d-flex align-center">
					<div class="fw-600 mr-2 my-auto">Filter By</div>

					<v-autocomplete
						:items="software_types"
						:menu-props="{ bottom: true, offsetY: true }"
						outlined
						clearable
						height="30px"
						v-model="customFilter.software_type"
						@change="statusFilterRows()"
						style="min-width: 300px !important; max-width: 300px !important"
						hide-details
						item-text="name"
						item-value="value"
						placeholder="Select Software Type"
					>
					</v-autocomplete>

					<div class="ml-2">
						<v-autocomplete
							:items="users"
							:menu-props="{ bottom: true, offsetY: true }"
							outlined
							clearable
							height="30px"
							v-model="customFilter.support"
							@change="statusFilterRows()"
							style="min-width: 300px !important; max-width: 300px !important"
							hide-details
							item-text="display_name"
							item-value="id"
							placeholder="Select Support"
						>
							<template v-slot:selection="data">
								<v-chip small v-bind="data.attrs" :input-value="data.selected">
									<v-avatar left>
										<v-img
											v-if="getValue(data, 'item.profile_img')"
											:src="getValue(data, 'item.profile_img')"
										></v-img>
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-avatar>
									{{ getValue(data, "item.display_name") }}
								</v-chip>
							</template>
							<template v-slot:item="data">
								<template>
									<v-list-item-avatar>
										<img
											v-if="getValue(data, 'item.profile_img')"
											:src="getValue(data, 'item.profile_img')"
										/>
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title>{{ getValue(data, "item.display_name") }}</v-list-item-title>
									</v-list-item-content>
								</template>
							</template>
						</v-autocomplete>
					</div>

					<div class="mx-2">
						<v-autocomplete
							:items="servers"
							:menu-props="{ bottom: true, offsetY: true }"
							outlined
							clearable
							height="30px"
							v-model="customFilter.server"
							@change="statusFilterRows()"
							style="min-width: 300px !important; max-width: 300px !important"
							hide-details
							item-text="name"
							item-value="value"
							placeholder="Select Server"
						>
						</v-autocomplete>
					</div>

					<DateRangePicker
						hide-details
						:key="clearDateRange"
						hide-top-margin
						clearable
						@click:clear="resetFilters"
						:inline-style="{ maxWidth: '250px', minWidth: '235px' }"
						class="mr-2"
						placeholder="Date Range"
						v-model="customFilter.daterange"
						:disablePastFrom="
							customFilter.daterange && Array.isArray(customFilter.daterange)
								? customFilter.daterange[0]
								: ''
						"
						@change="
							() => {
								if (customFilter.daterange && customFilter.daterange.length > 1) {
									statusFilterRows();
								}
							}
						"
					></DateRangePicker>

					<!-- Clear Filters -->
					<v-tooltip left>
						<template #activator="{ on, attrs }">
							<v-btn
								color="red darken-4"
								depressed
								@click="resetFilters()"
								class="mx-2"
								tile
								min-height="30px"
								x-small
								v-on="on"
								v-bind="attrs"
							>
								<v-icon color="#fff">mdi-close</v-icon>
							</v-btn>
						</template>
						<span class="fw-500">Clear filers and pagination</span>
					</v-tooltip>

					<!-- <v-btn color="blue darken-4 white--text" tile min-height="30px" depressed class="mx-3">
						<v-icon color="white">mdi-magnify</v-icon>
					</v-btn> -->

					<!-- Rearrange columns -->
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template #activator="{ on: menu, attrs }">
							<v-tooltip left>
								<template #activator="{ on: tooltip }">
									<v-btn
										depressed
										color="blue darken-4"
										class="text-white"
										tile
										min-height="30px"
										:disabled="pageLoading"
										x-small
										v-bind="attrs"
										v-on="{ ...tooltip, ...menu }"
									>
										<v-icon>mdi-table-edit</v-icon>
									</v-btn>
								</template>
								<span class="fw-500">Rearrange & toggle columns</span>
							</v-tooltip>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('softwares')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('seo')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</div>
			</div>
		</v-layout>

		<!-- Alphabet filter -->
		<div class="mb-2 overflow-auto" v-if="false">
			<v-btn-toggle v-model="toggle_exclusive" mandatory dense tile>
				<v-btn
					class="btn-border"
					:class="[
						{ 'blue darken-4 white--text': customFilter.alphabet == null },
						{ 'blue--text': customFilter.alphabet != null },
					]"
					@click="(customFilter.alphabet = null), statusFilterRows()"
				>
					ALL
				</v-btn>

				<v-btn
					class="btn-border"
					:class="[
						{ 'blue darken-4 white--text': customFilter.alphabet == data },
						{ 'grey lighten-4': customFilter.alphabet != data },
					]"
					:color="customFilter.alphabet != data ? 'grey lighten-4' : 'blue darken-4 '"
					v-for="(data, index) in filter"
					:key="'software_filter_alphabet_' + index"
					@click="(customFilter.alphabet = data), statusFilterRows()"
				>
					{{ data }}
				</v-btn>
			</v-btn-toggle>
		</div>

		<v-layout
			v-if="false"
			class="page-summary"
			style="display: grid; grid-template-columns: repeat(5, 1fr)"
			:class="summary_show ? 'show mt-2' : ''"
		>
			<template v-for="(statusData, key) in listingStatus">
				<PageSummeryBox
					:key="'Software_Status_' + key"
					:color="statusData.status_color"
					:text="statusData.text"
					:count="statusData.status_count"
					@click="statusData.value != status && statusFilterRows(statusData.value)"
				/>
			</template>
		</v-layout>

		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Domain #, Target Country, Sales Person, Category"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>

		<template v-if="pageLoading">
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>

		<div v-else class="v-data-table bt-table theme--light mt-2">
			<div class="v-data-table__wrapper">
				<table class="w-100">
					<thead>
						<tr>
							<template v-for="(th, key) in sortedThead">
								<th :key="'Software_Listing_' + key" :width="th.width">
									<v-layout>
										<v-flex class="text-left">
											<template v-if="th.checkbox">
												<v-checkbox
													dense
													v-model="checkAll"
													:ripple="false"
													hide-details
													color="blue darken-4"
													class="m-0 p-0"
												></v-checkbox>
											</template>
											<template v-else-if="th.key == 'software_type'">
												<v-icon color="blue darken-3" class="mr-1">mdi-desktop-classic</v-icon>
												{{ th.name }}
											</template>
											<template v-else-if="th.key == 'dates'">
												<v-icon color="blue darken-3" class="mr-1">mdi-calendar</v-icon>
												{{ th.name }}
											</template>
											<!-- <template v-else-if="th.key == 'cost'">
												<v-icon color="blue darken-3" class="mr-1">mdi-currency-usd</v-icon>
												{{ th.name }}
											</template> -->
											<template v-else-if="th.key == 'support'">
												<v-icon color="blue darken-3" class="mr-1">mdi-face-agent</v-icon>
												{{ th.name }}
											</template>
											<template v-else-if="th.key == 'server'">
												<v-icon color="blue darken-3" class="mr-1">mdi-server-network</v-icon>
												{{ th.name }}
											</template>
											<template v-else-if="th.key == 'recurring'">
												<v-icon color="blue darken-3" class="mr-1">mdi-update</v-icon>
												{{ th.name }}
											</template>
											<template v-else>
												{{ th.name }}
											</template>
										</v-flex>
										<v-flex v-if="!th.checkbox && th.sortable" class="text-right ml-2">
											<template v-if="th.sort == 'ASC'">
												<v-icon small link>mdi-sort-ascending</v-icon>
											</template>
											<template v-if="th.sort == 'DESC'">
												<v-icon small link>mdi-sort-descending</v-icon>
											</template>
										</v-flex>
									</v-layout>
								</th>
							</template>
						</tr>
					</thead>
					<tbody>
						<template v-if="tbody && tbody.length">
							<tr v-for="(software, b_key) in tbody" :key="'$_body_' + b_key">
								<template v-for="(th, hkey) in sortedThead">
									<!-- goSoftwareDetail(th, software)-->
									<td
										@click="goSoftwareDetail(software.id)"
										:key="'head_' + hkey"
										:style="{ minWidth: th.key == 'notes' ? '300px' : 'auto' }"
										:class="{ 'cursor-pointer': !th.checkbox && th.key != 'action' }"
									>
										<template v-if="th.checkbox">
											<v-checkbox
												dense
												:ripple="false"
												:value="software.id"
												hide-details
												v-model="selectedRows"
												multiple
												@change="selectRow"
												class="m-0 p-0"
												color="blue darken-4"
											></v-checkbox>
										</template>

										<template v-if="th.key == 'action'">
											<div @click.stop>
												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-icon
															size="20"
															v-on="on"
															v-bind="attrs"
															class="mr-2 cursor-pointer pa-1"
															style="border: 1px solid #2196f3; border-radius: 15%"
															@click="editSingleData(software)"
															color="blue"
															v-if="getPermission('seo:update')"
															>mdi-pencil
														</v-icon>
													</template>
													<span>Edit</span>
												</v-tooltip>

												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-icon
															v-on="on"
															v-bind="attrs"
															size="20"
															v-if="getPermission('seo:delete')"
															style="border: 1px solid #f44336; border-radius: 15%"
															@click="doAction(software, 'delete')"
															class="cursor-pointer pa-1"
															color="red"
														>
															mdi-delete
														</v-icon>
													</template>
													<span>Delete</span>
												</v-tooltip>
											</div>
										</template>

										<template v-if="th.key == 'software_type'">
											<template v-if="getValue(software, 'software_type')">
												<!-- <div>
													<v-icon color="blue darken-3" class="mb-2">mdi-desktop-classic</v-icon>
													style="top: 0px; position: relative; left: -1px"
												</div> -->
												<v-chip label outlined color="blue darken-4">
													<!-- <v-icon style="color: white" size="17" left>mdi-desktop-classic</v-icon> -->
													<span class="fw-600">{{ getValue(software, "software_type") }}</span>
												</v-chip>
												<div class="mt-1" v-if="getValue(software, 'tags')">
													<template v-for="(tag, idx) in getValue(software, 'tags')">
														<v-chip
															v-if="idx < 2"
															:key="tag.id + idx"
															:class="{
																'ml-1': idx > 0,
															}"
															x-small
															:color="tag.color"
														>
															<span>{{ tag.tag }}</span>
														</v-chip>
													</template>
													<div v-if="getValue(software, 'tags').length > 2">
														...
														<v-chip class="mt-1" x-small color="blue">
															<span class="white--text">+{{ getValue(software, "tags").length - 2 }} more</span>
														</v-chip>
													</div>
												</div>
											</template>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'dates'">
											<template v-if="getValue(software, 'sale_date')">
												<!-- <div>
													<v-icon
														v-if="getValue(software, 'sale_date')"
														color="blue darken-3"
														style="top: 0px; position: relative; left: -1px"
														class="mb-2"
														>mdi-calendar</v-icon
													>
												</div> -->
												<!-- <v-chip v-if="getValue(software, 'sale_date')" label outlined color="rgb(120 97 208)">
													<span class="fw-600">{{ formatDate(getValue(software, "sale_date")) }}</span>
												</v-chip> -->

												<div v-if="getValue(software, 'sale_date')" class="d-flex align-center">
													<span style="color: #434343; min-width: 96px" class="fw-600">Sale : </span>&nbsp;
													<v-chip label outlined color="rgb(120 97 208)" x-small>
														<span class="fw-600">{{ formatDate(getValue(software, "sale_date")) }}</span>
													</v-chip>
												</div>

												<div
													v-if="getValue(software, 'live_expected')"
													style="margin-top: 2px"
													class="d-flex align-center"
												>
													<span style="color: #434343; min-width: 96px" class="fw-600">Live Expected : </span
													>&nbsp;
													<v-chip label outlined color="blue" x-small>
														<span class="fw-600">{{ formatDate(getValue(software, "live_expected")) }}</span>
													</v-chip>
												</div>

												<div
													v-if="getValue(software, 'live_actual')"
													style="margin-top: 2px"
													class="d-flex align-center"
												>
													<span style="color: #434343; min-width: 96px" class="fw-600">Live Actual : </span
													>&nbsp;
													<v-chip label outlined color="#31aba7" x-small>
														<span class="fw-600">{{ formatDate(getValue(software, "live_actual")) }}</span>
													</v-chip>
												</div>
												<!-- <div class="text-center" v-else>-</div> -->
											</template>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'server'">
											<template v-if="getValue(software, 'server') && getValue(software, 'server_data.name')">
												<!-- <v-icon color="blue darken-3" class="mb-2">mdi-server-network</v-icon> -->
												<div class="d-flex align-center">
													<span
														:style="{
															color: '#434343',
															minWidth: getValue(software, 'service_provider') ? '113px' : '',
														}"
														class="fw-600"
														>Name : </span
													>&nbsp;
													<v-chip label outlined color="#6c810d" x-small>
														<!-- <v-icon size="15" color="white" left>mdi-server-network</v-icon> -->
														<span class="fw-600">{{ getValue(software, "server_data.name") }}</span>
													</v-chip>
												</div>

												<div
													v-if="getValue(software, 'service_provider')"
													style="margin-top: 2px"
													class="d-flex align-center"
												>
													<span style="color: #434343; min-width: 113px" class="fw-600">Service Provider : </span
													>&nbsp;
													<v-chip label outlined color="#31aba7" x-small>
														<span class="fw-600">{{ getValue(software, "service_provider") }}</span>
													</v-chip>
												</div>
											</template>
											<div class="text-center" v-else>-</div>
										</template>

										<template v-if="th.key == 'cost'">
											<template v-if="getValue(software, 'total_cost')">
												<!-- <v-icon
													color="blue darken-3"
													style="top: 0px; position: relative; left: -6px"
													class="mb-2"
													>mdi-currency-usd</v-icon
												> -->
												<!-- <v-chip label color="rgb(69 169 42)"> -->
												<p style="color: #434343" class="fw-600">
													{{ formatMoney(getValue(software, "total_cost")) }}
												</p>
												<!-- </v-chip> -->
											</template>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'is_grant'">
											<div v-if="Boolean(software.is_grant)">
												<div v-if="getValue(software, 'grant_type')" class="d-flex align-center">
													<span class="fw-600" style="color: #434343; min-width: 121px">Grant Type : </span
													>&nbsp;
													<v-chip label outlined color="indigo" x-small>
														<v-icon size="17" left>mdi-card-account-details-outline</v-icon>
														<span class="fw-600 text-uppercase">{{ software.grant_type }}</span>
													</v-chip>
												</div>
												<!-- <div
													v-if="getValue(software, 'loa_date')"
													style="margin-top: 2px"
													class="d-flex align-center justify-content-between"
												>
													<span class="fw-600" style="color: #434343">LOA Date : </span>&nbsp;
													<v-chip label outlined color="#f64e60" x-small>
														<v-icon size="17" left>mdi-calendar</v-icon>
														<span class="fw-600">{{ formatDate(software.loa_date) }}</span>
													</v-chip>
												</div> -->
												<div
													v-if="getValue(software, 'filling_date')"
													style="margin-top: 2px"
													class="d-flex align-center"
												>
													<span class="fw-600" style="color: #434343; min-width: 121px"
														>Grant Filling Date : </span
													>&nbsp;
													<v-chip label outlined color="#2196f3" x-small>
														<v-icon size="17" left>mdi-calendar</v-icon>
														<span class="fw-600">{{ formatDate(software.filling_date) }}</span>
													</v-chip>
												</div>
												<!-- <div
													v-if="getValue(software, 'system_live')"
													style="margin-top: 2px"
													class="d-flex align-center justify-content-between"
												>
													<span class="fw-600" style="color: #434343">System Live : </span>&nbsp;
													<v-chip label outlined color="rgb(43 138 216)" x-small>
														<v-icon size="17" left>mdi-calendar</v-icon>
														<span class="fw-600">{{ formatDate(software.system_live) }}</span>
													</v-chip>
												</div> -->
												<div
													style="margin-top: 2px"
													v-if="getValue(software, 'claim_deadline')"
													class="d-flex align-center"
												>
													<span class="fw-600" style="color: #434343; min-width: 121px">Claim Deadline : </span
													>&nbsp;
													<v-chip label outlined color="#f44336" x-small>
														<v-icon size="17" left>mdi-calendar</v-icon>
														<span class="fw-600">{{ formatDate(software.claim_deadline) }}</span>
													</v-chip>
												</div>
											</div>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'support'">
											<template
												v-if="
													getValue(software, 'project_manager') ||
													getValue(software, 'primary_support') ||
													getValue(software, 'secondary_support')
												"
											>
												<!-- <v-icon
													color="blue darken-3"
													style="top: 0px; position: relative; left: -1px"
													class="mb-2"
													>mdi-face-agent</v-icon
												> -->
												<!-- data.tbody[1].project_manager.display_name -->
												<div
													v-if="getValue(software, 'project_manager.display_name')"
													class="d-flex align-center"
												>
													<span style="color: #434343; min-width: 116px" class="fw-600">Project Manager : </span
													>&nbsp;
													<v-chip outlined label color="indigo">
														<!-- <v-icon size="17" color="white" left>mdi-account-tie</v-icon> -->
														<v-avatar left>
															<img
																style="max-width: 25px"
																:src="
																	getValue(software, 'project_manager.profile_img')
																		? getValue(software, 'project_manager.profile_img')
																		: 'https://ams-dev.businessthrust.com//media/misc/no_photo_found.png'
																"
																alt="Entity"
															/>
														</v-avatar>
														<span class="fw-600 text-capitalize">{{
															getValue(software, "project_manager.display_name")
														}}</span>
													</v-chip>
												</div>
												<div
													v-if="getValue(software, 'primary_support_data.display_name')"
													style="margin-top: 2px"
													class="d-flex align-center"
												>
													<span style="color: #434343; min-width: 116px" class="fw-600">Primary : </span>&nbsp;
													<v-chip outlined label color="#f64e60">
														<!-- <v-icon size="17" color="white" left>mdi-account</v-icon> -->
														<v-avatar left>
															<img
																style="max-width: 25px"
																:src="
																	getValue(software, 'project_manager.profile_img')
																		? getValue(software, 'project_manager.profile_img')
																		: 'https://ams-dev.businessthrust.com//media/misc/no_photo_found.png'
																"
																alt="Entity"
															/>
														</v-avatar>
														<span class="fw-600 text-capitalize">{{
															getValue(software, "primary_support_data.display_name")
														}}</span>
													</v-chip>
												</div>
												<div
													v-if="getValue(software, 'secondary_support_data.display_name')"
													style="margin-top: 2px"
													class="d-flex align-center"
												>
													<span style="color: #434343; min-width: 116px" class="fw-600">Secondary : </span>&nbsp;
													<v-chip outlined label color="#1bc5bd">
														<!-- <v-icon size="17" color="white" left>mdi-account</v-icon> -->
														<v-avatar left>
															<img
																style="max-width: 25px"
																:src="
																	getValue(software, 'project_manager.profile_img')
																		? getValue(software, 'project_manager.profile_img')
																		: 'https://ams-dev.businessthrust.com//media/misc/no_photo_found.png'
																"
																alt="Entity"
															/>
														</v-avatar>
														<span class="fw-600 text-capitalize">{{
															getValue(software, "secondary_support_data.display_name")
														}}</span>
													</v-chip>
												</div>
											</template>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'status'">
											<div v-if="getValue(software, 'status') == 3" class="d-flex align-center">
												<v-chip label color="#30a630" class="fw-600">
													<span class="white--text">Live</span>
												</v-chip>
												<div class="circles">
													<div style="background-color: #30a630" class="circle1"></div>
													<div style="background-color: #30a630" class="center-live"></div>
												</div>
											</div>

											<v-chip
												v-else-if="getValue(software, 'status') == 2"
												label
												color="orange"
												class="fw-600"
											>
												<span class="white--text">Development</span>
											</v-chip>

											<v-chip v-else-if="getValue(software, 'status') == 1" label color="cyan" class="fw-600">
												<span class="white--text">Draft</span>
											</v-chip>

											<v-chip v-else-if="getValue(software, 'status') == 4" label color="red" class="fw-600">
												<span class="white--text">Cancelled</span>
											</v-chip>
										</template>

										<template v-if="th.key == 'recurring'">
											<div v-if="getValue(software, 'recurring_date')" class="d-flex align-center">
												<span style="color: #434343; min-width: 94px" class="fw-600">Date : </span>&nbsp;
												<v-chip label outlined color="rgb(182 97 185)" x-small>
													<v-icon size="17" left>mdi-calendar</v-icon>
													<span class="fw-600">{{ formatDate(getValue(software, "recurring_date")) }}</span>
												</v-chip>
											</div>

											<div
												v-if="getValue(software, 'no_of_users')"
												style="margin-top: 2px"
												class="d-flex align-center"
											>
												<span style="color: #434343; min-width: 94px" class="fw-600">No of Users : </span>&nbsp;
												<v-chip label outlined color="rgb(148 167 59)" x-small>
													<v-icon size="17" left>mdi-account-multiple</v-icon>
													<span class="fw-600">{{ getValue(software, "no_of_users") }}</span>
												</v-chip>
											</div>

											<!-- <div
												v-if="getValue(software, 'total_cost')"
												style="margin-top: 2px"
												class="d-flex align-center justify-content-between"
											>
												<span style="color: #434343" class="fw-600">Total Cost : </span>&nbsp;
												<v-chip label outlined color="#31aba7" x-small>
													<span class="fw-600">{{ formatMoney(getValue(software, "total_cost")) }}</span>
												</v-chip>
											</div> -->

											<div
												v-if="getValue(software, 'cost_per_user')"
												style="margin-top: 2px"
												class="d-flex align-center"
											>
												<span style="color: #434343; min-width: 94px" class="fw-600">Cost Per User : </span
												>&nbsp;
												<v-chip label outlined color="rgb(120 97 208)" x-small>
													<span class="fw-600">{{ formatMoney(getValue(software, "cost_per_user")) }}</span>
												</v-chip>
											</div>
										</template>
									</td>
								</template>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td colspan="20">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no software at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<!-- </div> -->

		<v-row v-if="tbody.length">
			<v-col md="6" class="my-auto">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					v-model="page"
					:length="totalPage"
					total-visible="9"
					v-on:input="updatePagination($event)"
				></v-pagination>
			</v-col>
		</v-row>

		<!-- <v-pagination class="listing-pagination" :length="totalPage" v-model="currentPage"> </v-pagination> -->

		<DeleteTemplate
			v-if="deleteDialog"
			type="Software"
			delete-text="Software"
			v-on:success="successDeleteSEOProject"
			v-on:close="deleteDialog = false"
			:delete-dialog="deleteDialog"
			delete-url="seo/project/keyword-analysis/${id}?type=keyword_analysis"
		>
		</DeleteTemplate>

		<!-- v-on:success="successDeleteSEOProject" -->

		<Dialog :dialog="development" :dialog-width="500" @close="development = false">
			<template #title>
				<h1>Under development</h1>
			</template>
		</Dialog>
	</div>
	<!-- </v-sheet> -->
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<style>
.circles {
	position: relative;
	height: 50px;
	width: 50px;

	> div.circle1 {
		animation: growAndFade 1.5s infinite ease-out;

		border-radius: 50%;
		/* height: 100%;
    	width: 100%; */
		opacity: 0;
		position: absolute;
		height: 50px;
		width: 50px;
	}

	.circle1 {
		animation-delay: 0.1s;
	}

	.center-live {
		border-radius: 50%;
		position: relative;
		top: 17px;
		left: 17px;
		height: 15px;
		width: 15px;
	}
}

@keyframes growAndFade {
	0% {
		opacity: 0.25;
		transform: scale(0);
	}
	100% {
		opacity: 0;
		transform: scale(1);
	}
}

/* 
	body {
	  align-items: center;
	  display: flex;
	  height: 100vh;
	  justify-content: center;
	  margin: 0;
	}
*/
</style>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import MainMixin from "@/core/mixins/main.mixin";
import HeightMixin from "@/core/mixins/height.mixin";
import SettingsMixin from "@/core/mixins/settings.mixin";
import DateRangePicker from "@/view/components/DateRangePickerV2";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import { mapGetters } from "vuex";
import { SET_TBODY } from "@/core/services/store/listing.module";
import {
	SET_SELECTED_ROWS,
	SET_CURRENT_PAGE,
	SET_SHOWING_STRING,
	SET_COUNT,
	SET_TOTAL_PAGE,
} from "@/core/services/store/listing.module";
import { QUERY /* , GET */ } from "@/core/services/store/request.module";
import objectPath from "object-path";
import Dialog from "@/view/components/Dialog";
import PageSummeryBox from "@/view/components/PageSummeryBox";
/* import { toSafeInteger } from "lodash"; */
/* 	
	import SelectInput from "@/view/components/SelectInput"; 
	import ImageTemplate from "@/view/components/Image"; 
*/

export default {
	name: "Software-Listing",
	mixins: [ListingMixin, HeightMixin, MainMixin, SettingsMixin],
	data() {
		return {
			skipBottomHeight: 56,
			statusFilterData: [],
			endpoint: "softwares",
			settingsEndpoint: "settings-softwares",
			pageTitle: "Software",
			query: null,
			summary_show: true,
			page: 1,
			deleteDialog: false,
			checkbox: false,
			status: "all",
			customFilter: {
				/* current_page: 1,
				search: null,
				page: 1, */

				software_type: null,
				support: null,
				server: null,
				daterange: [],
				alphabet: null,
				page: 1,
			},
			clearDateRange: +new Date(),
			notification_on: "Select Notification On...",
			toggle_exclusive: null,
			filter: [
				"a",
				"b",
				"c",
				"d",
				"e",
				"f",
				"g",
				"h",
				"i",
				"j",
				"k",
				"l",
				"m",
				"n",
				"o",
				"p",
				"q",
				"r",
				"s",
				"t",
				"u",
				"v",
				"w",
				"x",
				"y",
				"z",
			],
			id: null,
			selectedRows: [],
			indeterminate: false,
			development: false,
			software_types: [],
			servers: [],
			users: [],

			/* editDialog: false, */
			/* seoId: null, */
			/* colorCount: 0, */
			/* statusDialog: false, */
			/* projectStatus: 0, */
			/* contact_person: null,
			invoice_number: null, */
			/* notification: null, */
			/* usersList: [], */
			/* sale_person: "", */
			/* seoProjectList: [], */
			/* entityList: [
				{
					text: "BThurst",
					value: "bthurst",
				},
				{
					text: "Genic Solution",
					value: "genic_solution",
				},
			], */
			/* projectCreateDialog: false, */
			/* categoryList: [], */
			/* project: "", */
			/* date_range: [], */
		};
	},
	methods: {
		getValue(object, path) {
			return objectPath.get(object, path);
		},
		hasKey(object, path) {
			return objectPath.has(object, path);
		},
		goSoftwareDetail(id) {
			// console.log({td});
			// this.development = true;
			//let id = td.id;
			this.$router.push({
				name: "software-detail",
				params: { id },
				query: { t: new Date().getTime() },
			});

			// if (th.checkbox == false && th.key != "action") {
			// 	this.$router.push({
			// 		name: `seo-detail`,
			// 		params: {
			// 			id: domain.id,
			// 			// sales: domain.sales_owner.display_name
			// 		},
			// 	});
			// }
		},
		resetFilters() {
			this.status = "all";
			this.customFilter = {
				software_type: null,
				support: null,
				server: null,
				daterange: [],
				alphabet: null,
				page: 1,
			};
			this.clearDateRange = +new Date();
			this.filter_user = 0;
			this.statusFilterRows();
		},
		doAction(row, param) {
			switch (param) {
				case "download":
					window.open(row.url, "_blank");
					break;
				case "delete":
					this.id = row.id;
					this.deleteDialog = true;
					break;
			}
		},
		statusFilterRows(statusvalue) {
			if (statusvalue) {
				this.status = statusvalue;
			}

			this.pageLoading = true;

			let payload = {
				status: this.status || "all",
				software_type: this.customFilter.software_type || null,
				support: this.customFilter.support || null,
				server: this.customFilter.server || null,
				daterange: this.customFilter.daterange || [],
				alphabet: this.customFilter.alphabet || null,
				search: this.listingSearch || null,
			};

			this.allFiltersStatus(payload);

			this.$store
				.dispatch(QUERY, {
					url: `softwares`,
					data: payload /* {
						status: this.status,
						user_filter: this.customFilter.user_filter,
						daterange: this.customFilter.daterange,
						current_page: this.customFilter.current_page,
						search: this.customFilter.search,
						entity: this.customFilter.entity,
						category: this.customFilter.category,
						alphabet: this.customFilter.alphabet,
					} */,
				})
				.then((data) => {
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_COUNT, data.count);
					this.$nextTick(() => {
						this.calculateHeight();
					});
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersStatus(payload) {
			this.$router.push({
				name: "software",
				query: {
					/* status: this.status,
					user_filter: this.customFilter.user_filter,
					daterange: this.customFilter.daterange,
					current_page: this.customFilter.current_page,
					search: this.customFilter.search,
					category: this.customFilter.category,
					entity: this.customFilter.entity,
					alphabet: this.customFilter.alphabet, */
					...payload,
					page: 1,
				},
			});
		},
		selectRow() {
			this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.filterRows();
			});
		},
		// getSettings() {
		// 	const _this = this;
		// 	_this.$store
		// 		.dispatch(GET, { url: "lead-setting" })
		// 		.then((data) => {
		// 			_this.usersList = data.users || [];
		// 			_this.categoryList = data.category || [];
		// 		})
		// 		.catch((error) => {
		// 			console.log({ error });
		// 		})
		// 		.finally(() => {
		// 			_this.pageLoading = false;
		// 		});
		// },
		/* mod_string(text, saperator = "", joiner = "") {
			let splitArr = text ? text.split(saperator) : [];
			let mod_arr = splitArr.map((v) => v.charAt(0).toUpperCase() + v.slice(1));
			return mod_arr.join(joiner);
		}, */
		/* updatePageData() {
			return Math.ceil(this.seoProjectList.length / this.itemsPerPage);
		}, */
		/* getStatusColor(status) {
			if (status == "Expired") {
				return "red";
			} else if (status == "Expire In 1 Month") {
				return "orange";
			} else if (status == "Active") {
				return "green";
			}
		}, */
		/* getSoftwareTypes() {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `software-settings/list`,
				})
				.then((data) => {
					if (data) {
						this.software_types = data;
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		}, */
		/* selectAll() {
			this.selectedRows = [];
			if (this.checkbox) {
				for (let i = 0; i < this.tbody.length; i++) {
					this.selectedRows.push(this.tbody[i].id);
				}
			}
			this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
		}, */
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"listingStatus",
			"count",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
			"layoutConfig",
			"isMaster",
			"userType",
			"moduleSettings",
		]),
		checkAll: {
			get() {
				return this.tbody.length > 0
					? this.selectedRows.length == this.tbody.length
						? true
						: false
					: false;
			},
			set(value) {
				if (value) {
					let _selectedRows = this.tbody.map((v) => v.id);
					this.selectedRows = [..._selectedRows];
					this.$store.commit(SET_SELECTED_ROWS, [..._selectedRows]);
				} else {
					this.selectedRows = [];
					this.$store.commit(SET_SELECTED_ROWS, []);
				}
			},
		},
	},
	watch: {
		selected(param) {
			if (param.length < 1) {
				this.checkbox = false;
				this.indeterminate = false;
				this.selectedRows = [];
			}
		},
		currentPage(param) {
			this.page = param;
		},
		moduleSettings: {
			deep: true,
			immediate: true,
			handler(param) {
				if (param) {
					this.software_types = param.software_type || [];
					this.servers = param.server || [];
					this.users = param.users || [];
				}
			},
		},
	},

	mounted() {
		// this.getSettings();
		/* this.getSoftwareTypes(); */
		// this.customFilter.current_page = this.$route.query.current_page;
		// this.getCustomerStatus();
		// LeaveEventBus.$on("refresh", () => {
		// 	this.getListing();
		// });
	},
	beforeMount() {
		let query = this.$route.query;
		if (Object.keys(query).length > 1) {
			this.customFilter.alphabet = query.alphabet || null;
			this.status = query.status || "all";
			this.customFilter.software_type = query.software_type || null;
			this.customFilter.support = +query.support || null;
			this.customFilter.server = query.server || null;
			this.customFilter.daterange = query.daterange || [];
			/* this.customFilter.current_page = query.current_page || 1; */
		}
		/* this.customFilter.support = toSafeInteger(this.$route.query.support); */
		/* if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_filter) {
			this.customFilter.support = toSafeInteger(this.$route.query.user_filter);
		} */
	},
	components: {
		/* SelectInput, */
		/* ImageTemplate, */
		DateRangePicker,
		Dialog,
		DeleteTemplate,
		PageSummeryBox,
	},
};
</script>

<style>
.bTable_height {
	height: calc(100vh - 233px) !important;
}
</style>
